

.intro_descriptor {
  padding: 40px;
  margin: 150px 0;
  background-color: #0000005e;
  box-shadow: 0px 2px 20px black;

  text-shadow: 0px 0px 4px black;
  font-family: 'Alex-brush';
  font-size: 37px;
}


.content {
  margin: auto;
  padding: 20px 20px 0;
  /* height: calc(100% - 82px); */
  max-width: 1300px;
  /* background-color: white; */
}

.content_wrapper {
  display: inline-block;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 0 10px #00000052;
  background-color: white;
}

.img_content {
  max-width: 100%;
  width: 600px;
}





@media (max-width: 500px) {
  .intro_descriptor {
    font-size: 25px;
  }
}