.policy_wrapper {
  max-width: 1100px;
  margin: 20px auto;
  padding: 20px;
  color: black;
  text-align: left;
  background-color: white;
  border-radius: 20px;
}
.policy_wrapper a {
  color: black;
  text-decoration: underline;
}
.policy_title {
  text-align: center;
}