.store_item {
  display: inline-block;
  margin: 18px;
}

.mid_content_title {
  font-family: Alex-brush;
  font-size: 30px;
  text-shadow: 0px 0px 7px black;
}

.book_review_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
}
.book_review {
  position: relative;
  text-align: left;
  height: 347px;
  overflow: hidden;
  padding: 7px;
  border-radius: 15px;
  box-shadow: black 0px -80px 60px -60px inset,
              #5b5b5b96 3px 3px 7px;
  background-color: white;
  color: black;
  transition: ease 0.3s;
  cursor: pointer;
}
.book_review p {
  margin-top: 0;
}
.book_review:hover {
  /* background-color: #47474794; */
}
.see_more_btn {
  position: sticky;
  bottom: 5px;
  padding: 6px 17px;
  width: 82px;
  margin: auto;
  border-radius: 100px;
  background-color: #0074d4;
  color: white;
  cursor: pointer;
}



@media (max-width: 500px) {
  .book_review_wrapper {
    flex-wrap: wrap;
  }
}