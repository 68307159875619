.footer_wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
  margin-top:50px;
  text-align: left;
  background-color: var(--mainColor3);
}
.footer_wrapper a {
  color: white;
  text-decoration: underline;
}
#logo_link {
  text-decoration: none;
}
.footer_wrapper .logo {
  float: unset;
}
.footer_block {
  margin: 45px;
}
ul {
  padding-inline-start: 20px;
}



@media (max-width: 500px) {
  .footer_block:first-child {
    display: none;
  }
  .footer_block {
    margin: 10px;
  }
}