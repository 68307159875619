
html {
  scroll-behavior: smooth;
}


:root {
  --mainColor: #3cabff;
  --mainColor2: #3cabff;
  --mainColor3: #0c273b;
  --mediaMaxWidth: 500px;
}


@font-face {
  font-family: Alex-brush;
  src: url(/public/fonts/alex-brush/AlexBrush-Regular.ttf);
  
}

a {
  text-decoration: none;
}
.logo a {
  color: black;
}

.main {
  scroll-behavior: smooth;
  
  height: 0vh;

  background-image: linear-gradient(to bottom, var(--mainColor), #ffffff);

  text-align: center;
  color: white;

  overflow-x: hidden;
  overflow-y: auto;

  transition: 2s;
}
