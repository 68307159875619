
.intro_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  position: relative;
  background-image: url("/public/pics/introBackground.jpeg");
  background-position: center;
  background-size: cover;

  opacity: 0.01;
  transition: 1s;

  width: 100%;
  height: 70vh;
}

.intro_pic {
  width: 200px;
}
.f_one {
  flex: 1;
}
.intro_wrapper .content_wrapper {
  border-radius: 8px;
  padding: 0;
  box-shadow: 0 0 15px #e8d6c7;
  font-size: 0;
  overflow: hidden;
}