.top_bar {
  padding: 15px 0;
  background-image: linear-gradient(to bottom, #ffffff, #9cd4ff);
}
.top_bar_container {
  max-width: 1100px;
  margin: auto;
  text-align: right;
}
.logo {
  float: left;
  font-family: Alex-brush;
  font-size: 35px;
}
.tabs {
  display: inline-flex;
  max-width: 50%;
}
.tab {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 10px;
  border-radius: 10px;
  background: var(--mainColor);
  cursor: pointer;
}
.tabs a {
  color: white;
}


@media (max-width: 600px) {
  .logo {
    font-size: 27px;
  }
}